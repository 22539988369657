import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue/es'
import './assets/styles/index.css';
import util from './util/common.js';
import moment from 'moment';

import VueI18n from 'vue-i18n'
import zh from '@/assets/languages/zh';
import en from '@/assets/languages/en';
Vue.prototype.$util = util;
Vue.prototype.$moment = moment;
Vue.config.productionTip = false

Vue.use(Antd);
Vue.use(VueI18n)

// Google Analytics代码
const script = document.createElement('script')
script.async = true
script.src = `https://www.googletagmanager.com/gtag/js?id=G-FHEFMC9H4P`
document.head.appendChild(script)

script.onload = () => {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', 'G-FHEFMC9H4P')
}
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en-us',
  messages: {
    'en-us': en,
    'zh-cn': zh
  }
})
//window.i18n = i18n;

new Vue({
  router,
  store,
  // dappJS,
  i18n,
  render: h => h(App)
}).$mount('#app')
