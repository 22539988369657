<template>
    <a-config-provider :locale="locale">
        <a-layout style="background-color: #131419;">
            <a-layout-content class="web_home">
                
                <div class="home_top_bg">
                    <a-carousel arrows autoplay>
                        <div
                          slot="prevArrow"
                          slot-scope="props"
                          class="custom-slick-arrow"
                          style="left: 24px;zIndex: 1"
                        >
                          <img src="~@/assets/home/left_i.png" alt="" style="width: 21px;">
                        </div>
                        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 24px">
                            <img src="~@/assets/home/right_i.png" alt="" style="width: 21px;">
                        </div>
                        <div style="position: relative;">
                            <img src="~@/assets/home/Group.png" alt="">
                            <a-row style="width:87%;margin:0 auto;text-align: left;position: absolute;left: 95px;top: 0;">
                                <a-col :span="9">
                                    <a-row class="guessTitle">
                                        {{$t('home.GUESSFREELYWINGIB')}}
                                    </a-row>
                                    <a-row class="guesContent">
                                        {{$t('home.GUESSOLTISTHEFIRST')}}
                                        {{$t('home.GUESSOLTISTHEFIRST_')}}
                                    </a-row>
                                    <a-row>
                                    <div class="guessBtn" @click="guess">
                                        <div style="margin: 0 auto;">
                                            {{$t('home.MAKEAGUESS')}} <img src="~@/assets/home/go_to.png" alt="" class="go_to" style="width: 24px;margin-left: 25px;">
                                        </div>
                                    </div>
                                    </a-row>
                                </a-col>
                            </a-row>
                        </div>
                    </a-carousel>
                    
                </div>
                <div class="messageBox">
                    <marqueeLeft></marqueeLeft>
                </div>
                <div style="margin: 33px 0;">
                    <!-- <a-row class="p_c_w_c">
                        <a-col :span="8" style="border-right: 1px solid #334083;">
                            <div class="p_c_w">546</div>
                            <div class="p_c_w_t">Pools</div>
                        </a-col>
                        <a-col :span="8" style="border-right: 1px solid #334083;">
                            <div class="p_c_w">42</div>
                            <div class="p_c_w_t">Categories</div>
                        </a-col>
                        <a-col :span="8">
                            <div class="p_c_w">378</div>
                            <div class="p_c_w_t">Winners</div>
                        </a-col>
                    </a-row> -->
                    <div class="lossless blue_bg">
                        <a-row style="width: 89%;margin: 0 auto;">
                            <a-col :span="10">
                                <img src="~@/assets/home/lessbg.png" alt="" class="loss_lf">
                            </a-col>
                            <a-col :span="2"></a-col>
                            <a-col :span="10">
                                <img src="~@/assets/home/less_icon.png" alt="" class="loss_icon">
                                <div class="loss_title">LOSSLESS</div>
                                <div class="loss_cont">
                                    Guesslot adopts a new and groundbreaking prediction mechanism, even if the prediction is not accurate, there will be no loss. So, rest assured and guess, the more funds staked, the more you will win.
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="lossless purple_bg">
                        <a-row style="width: 89%;margin: 0 auto;">
                            <a-col :span="10">
                                <img src="~@/assets/home/fair_icon.png" alt="" class="loss_icon">
                                <div class="loss_title">FAIR</div>
                                <div class="loss_cont">
                                    The result of the event comes from the decentralized Oracle, and the smart contract automatically judges and publishes the prediction result according to the event result. Decentralized blockchain technology makes predictions more fair and transparent.
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <img src="~@/assets/home/mobile.png" alt="" class="mobile">
                            </a-col>
                        </a-row>
                    </div>
                    <div class="lossless blue_bg">
                        <a-row style="width: 89%;margin: 0 auto;">
                            <a-col :span="10">
                                <img src="~@/assets/home/diversity.png" alt="" class="diver_lf">
                            </a-col>
                            <a-col :span="2"></a-col>
                            <a-col :span="10">
                                <img src="~@/assets/home/diver_icon.png" alt="" class="loss_icon">
                                <div class="loss_title">DIVERSITY</div>
                                <div class="loss_cont">
                                    The prediction market is comprehensive, covering the focus and hot events in finance, science and technology, sports, entertainment and other fields. There are always things you are good at and interested in.
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
                <a-row style="text-align:center;width: 87%;margin: 0 auto;">
                    <div class="guide">{{$t('home.HOWTOPLAY')}}</div>
                    <div class="easy">{{$t('home.SIMPLEPRODUCT')}}</div>
                </a-row>
                <div class="play_model">
                    <a-row>
                        <a-col :span="8">
                            <img src="~@/assets/home/play1.png" alt="" class="play_img">
                            <div class="p_title">DEPOSIT</div>
                            <div class="p_cont">Select a prize pool, deposit funds and obtain gTokens(deposit certificate). Guesslot will transfer the funds into superior and safe lending protocols like Aave to obtain stable income.</div>
                        </a-col>
                        <a-col :span="8">
                            <img src="~@/assets/home/play2.png" alt="" class="play_img">
                            <div class="p_title">PREDICT</div>
                            <div class="p_cont">Predict by staking gTokens, Guesslot uses high-reliability mainstream oracles to ensure fair and just results.</div>
                        </a-col>
                        <a-col :span="8">
                            <img src="~@/assets/home/play3.png" alt="" class="play_img">
                            <div class="p_title">WIN</div>
                            <div class="p_cont">The persons who predict correctly win the prizes, which come from the interest of the lending protocols. Other participants can get their full principal back without any loss.</div>
                        </a-col>
                    </a-row>
                </div>
                <a-row style="text-align:center;width: 87%;margin: 0 auto;">
                    <div class="guide">Predict</div>
                    <div class="easy">Find the events that interest you, predict and win the big prize!</div>
                </a-row>
                
                <div class="messageBox" style="margin-top: 98px;">
                    <predictList :sendVal="predictList" v-if="predictList.length !==0"></predictList>
                </div>
                <div class="guessBtn" style="margin: 0 auto;margin-top: 40px;" @click="guess">View all <img src="~@/assets/home/go_to.png" alt="" class="go_to" style="margin-left: 29px;"></div>
                
                <a-row style="text-align:center;width: 87%;margin: 0 auto;">
                    <div class="guide">Winners</div>
                    <div class="easy">Congratulations to all the winners！</div>
                </a-row>

                <!-- winner1 -->
                <div class="messageBox" style="margin-top: 48px;">
                    <winnerLeft :sendVal="userinfo1" v-if="userinfo1.length !==0"></winnerLeft>
                </div>
                <!-- winner2 -->
                <div class="messageBox" style="margin-top: 48px;">
                    <winnerRight :sendVal="userinfo2" v-if="userinfo2.length !==0"></winnerRight>
                </div>
                <a-row style="text-align:center;width: 87%;margin: 0 auto;">
                    <div class="guide">Partners</div>
                </a-row>
                <div class="partners_model">
                    <div style="flex: 1;" @click="go_page(1)"><img src="~@/assets/home/Primary_Logo.png" alt="" class="part_icon p_img_1"></div>
                    <div style="flex: 1;" @click="go_page(2)"><img src="~@/assets/home/AaveLogo.png" alt="" class="part_icon p_img_2"></div>
                    <div style="flex: 1;" @click="go_page(3)"><img src="~@/assets/home/ChainlinkLogo.png" alt="" class="part_icon p_img_3"></div>
                    <div style="flex: 1;" @click="go_page(4)"><img src="~@/assets/home/pinkSale.png" alt="" class="part_icon p_img_4"></div>
                    <div style="flex: 1;" @click="go_page(5)"><img src="~@/assets/home/dexview.png" alt="" class="part_icon p_img_5"></div>
                </div>
            </a-layout-content>
            <!-- <a-layout-content class="app_home">
                <div style="box-shadow: 0px 2px 6px 0px rgba(120, 118, 118, 0.3);background: linear-gradient(to left, #7B98FD 0%, #3763FD 100%);padding: 89px 50px 78px 50px;">
                    <img src="~@/assets/home/home_logo.png" alt="" style="width:644px;height:415px;">
                </div>
            </a-layout-content> -->
            <a-layout-footer style="background-color: #131419;">
                <a-row style="width: 87%;margin: 0 auto;padding: 67px 0 99px 0;">
                    <a-col :span="18"><img src="~@/assets/home/footer_logo.png" alt="" style="width: 20%;"></a-col>
                    <a-col :span="6">
                            <a-row>
                                <a-col :span="8">
                                    <div class="interLink">{{$t('footer.COMMUNIT')}}</div>
                                    <div class="twitterDiv" @click="twitter">
                                        <div class="link_txt">{{$t('footer.TWITTER')}}</div>
                                    </div>
                                    <div class="discordDiv" @click="goDiscord">
                                        <div style="margin-left:24px;" class="link_txt">{{$t('footer.DISCORD')}}</div>
                                    </div>
                                </a-col>
                                <a-col :span="8" style="text-align: center;">
                                    <div class="interLink">{{$t('footer.HELP')}}</div>
                                    <div style="margin-bottom:20px;" class="hoverBlue" @click="map">{{$t('footer.GUIDEES')}}</div>
                                    <div class="hoverBlue" @click="faqMap">{{$t('footer.FAQ')}}</div>
                                </a-col>
                                <a-col :span="8" style="text-align: center;">
                                    <div class="interLink">Security</div>
                                    <div style="margin-bottom:20px;" class="hoverBlue" @click="audit">Audit</div>
                                </a-col>
                            </a-row>
                    </a-col>
                </a-row>
            </a-layout-footer>
        </a-layout>
    </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
// 引入

import { useWallet } from "./hooks/useWallet";
const { useSubgraph } = useWallet;

import marqueeLeft from "./components/marquee.vue";
import predictList from "./components/predictList.vue";
import winnerLeft from "./components/winnerLf.vue";
import winnerRight from "./components/winnerRg.vue";
export default{
    // 注册组件
    components:{  marqueeLeft,predictList,winnerLeft,winnerRight },
    data(){
        return{
            langShow:false,
            account:'',
            name:'',
            predict:{
                asset:{
                    name:'',
                    time:''
                },
                settleTime:'',
                prize:'',
                stakeToken:{
                    balance:'',
                    symbol:''
                }
            },
            interLink:0,
            locale:zhCN,
            noticeList:[
                {text:'Guesslot has passed the Security Audit by Coinsult.'},
                {text:'Guesslot will be launched on Polygon in August.'},
                {text:'$GLT PRESALE will be launched on Pinksale at July 31 20:00 (UTC).'}
                // {text:'Will the Space X launch the starship successfully on Jun. 1st ?'},
                // {text:'Will the Space X launch the starship successfully on Jun. 1st ?'},
                // {text:'Will the Space X launch the starship successfully on Jun. 1st ?'},
            ],
            predictList:[],
            userinfo1:[],
            userinfo2:[]
        }
    },
    beforeDestroy () {
    },
    created(){
        
    },
    mounted(){
        this.getPredict();
        this.getWinner();
    },
    methods:{
        // 方法
        getSliderTitle (title) { // 获取点击的标题
           // console.log('title:', title)
        },
        connetWallet(){
            this.dappJS.connect("MetaMask").then(data => {
                // console.log('链接钱包');
                // console.log(data);
                let firstName = data.account.slice(0,4);
                let lastName = data.account.substr((data.account.length)-4);
                this.account = firstName+'...'+lastName;
                this.name = data.chain.name;
                this.interLink = 1;
                this.dappJS.GuesslotPool.getStakeTokenBalance().then(data => {
                    this.$store.commit('getBalance',data.balance);
                    this.$store.commit('getSymbol',data.symbol);
                })
            })
            .catch(data => {
                console.log("catch", data);
            });
        },
        goto(e){
            this.$router.push(e);
        },
        guess(){
            let routeData = this.$router.resolve({ path: '/predictlist', query: { id: ''} });
            window.open(routeData.href, '_blank');
           // this.$router.push("/predictlist");
            //this.$router.push({path:'/predict',query: {id:'1'}})
        },
        goDiscord(){
            window.open('https://discord.gg/S8gMKn8CbR');
        },
        map(){
            window.open('https://docs.guesslot.com/');
        },
        faqMap(){
            window.open(' https://docs.guesslot.com/'); 
        },
        twitter(){
            window.open('https://twitter.com/Guesslot_DAO');
        },
        audit(){
            window.open('https://github.com/Coinsult/solidity/blob/main/Coinsult_Guesslot_0x1b...64a4_Audit.pdf');
        },
        go_page(e){
            switch(e){
                case 1 :
                    window.open('https://polygon.technology/');
                    break;
                case 2 :
                    window.open('https://aave.com/');
                    break;
                case 3 :
                    window.open('https://chain.link/');
                    break;
                case 4 :
                    window.open('https://www.pinksale.finance');
                    break;
                case 5 :
                    window.open('https://www.dexview.com');
                    break;
            }
        },
        goPinkSale(){
            window.open("https://www.pinksale.finance/launchpad/0xE2758c512B4F7D587183bD77D2619647e65eCe8f?chain=Matic","_blank");     
        },
        getWinner(){
            useSubgraph().getWinners(0).then((data) => {
                //console.log(data, "getWinners");
                if(data.length > 0){
                    let allData = data;
                    let initialVal = data;
                    //先判断winners有没有满20个人 如果不够20个人 那么补齐20个人
                    let lg = data.length;
                    if(lg < 20){
                        let rollLg = 20 - lg; // 20-3 = 17 
                        if(rollLg < lg){
                            //
                            for(var i = 0 ; i < rollLg;i++){
                                if(allData.length == 20){
                                    return false
                                }else{
                                    allData.push(data[i]);
                                }
                            }
                        }else{
                            let circulate = rollLg / lg; //除以倍数 看看需要循环几次 
                            if(circulate*lg == rollLg){
                                //是可以整除的 所以直接循环
                                for(var i = 0 ; i < (circulate);i++){
                                    allData = allData.concat(initialVal);
                                }
                            }else{
                                //不能整除那么就多循环一次 然后再取最前面的20条数据
                                for(var i = 0 ; i < (circulate+1);i++){
                                    allData = allData.concat(initialVal);
                                }
                                allData.splice(0,20)
                            }
                        }
                    }
                    allData.map((item,index) => {
                        let str = item.account;
                        let subStr1 = str.substring(0,4);
                        let subStr2 = str.substring(str.length - 4,str.length);
                        let subStr = subStr1 + "..." + subStr2;
                        item.account = subStr;
                        item.url = 'head'+index;
                        item.roll = parseFloat(Number(item.rewards).toFixed(8)) + ' ' + item.token
                    })
                // console.log('处理后的data',allData);
                    this.userinfo1 = allData.slice(0,10);
                    this.userinfo1 = this.userinfo1.concat(this.userinfo1);
                    this.userinfo2 = allData.slice(11,20);
                    this.userinfo2 = this.userinfo2.concat(this.userinfo2);
                }
            })
            .catch((error) => {
                console.log(error,'winnerError');
            })
        },
        getPredict(){
            useSubgraph().getEvents('gUSDT', 'Predicting', [], '', 0)
            .then((data) => {
            //    console.log(data,'predict........');
                if(data.length >= 5){
                    this.predictList = data.slice(0,5);
                }else{
                    this.predictList = data;
                }
                useSubgraph().getEvents('USDT', 'Predicting', [], '', 0)
                .then((data1) => {
                //    console.log(data1,'usdt。。。。。。');
                    if(data1.length > 0){
                        this.predictList = this.predictList.concat(data1);
                        let copylist = this.predictList;
                        if(this.predictList.length >= 10){
                            this.predictList = this.predictList.slice(0,10);
                        }else{
                            let lg = this.predictList.length;
                            let mul = Math.floor(10/lg);
                                for(var i = 0; i<mul;i++){  
                                    this.predictList = this.predictList.concat(copylist);
                                }
                        }
                        this.predictList = this.predictList.slice(0,10);
                        this.predictList = this.predictList.concat(this.predictList);
                        //console.log(this.predictList,'preict');
                    }
                    
                })
            })
        }
        
    }
}
</script>
<style scoped>
/* @media screen and (min-width:360px) and (max-width:1080px) {
    .app_home{
        display: inline-block;
    }
    .web_home{
        display: none;
    }
}
@media screen and (min-width:1080px) {
    .app_home{
        display: none;
    }
    .web_home{
        display: inline-block;
    }
} */

.logoTxt{
  font-size: 24px;
  font-family: 'Bold';
  color: #FACC03;
}
#navList a{
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
}
::v-deep .router-link-exact-active{
    color: #FFFFFF!important;
}

.home_top_bg{
    width: 100%;
    margin-top: 83px;
    height: 25%;
}
#mainnet{
    width: 250px;
    height: 36px;
    background: rgba(24, 117, 240, 0);
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-family: 'Regular';
    font-size: 14px;
    padding: 7px 10px;
    float: right;
    margin-right: 28px;
}
.status{
    width: 10px;
    height: 10px;
    background: #00A856;
    border-radius: 50%;
    margin-top: 5px;
}
.netbtn{
    width: 80px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    line-height: 20px;
    text-align: center;
}
#language{
    width:22px!important;
    height: 22px;
    background: url('~@/assets/home/language.png') no-repeat;
    border:0;
    background-size: 100% 100%;
}
.langChoice{
    width: 100px;
    height: 86px;
    box-shadow: 0px 0px 20px 0px rgba(150, 157, 171, 0.1);
    border-radius: 12px;
    position: absolute;
    top: 76px;
    right: 23px;
    text-align: center;
}
.langH{
    height: 43px;
    line-height: 43px;
    cursor: pointer;
}
.ellipsis{
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    width: 120px;
}
.interlink{
    width: 250px;
    height: 36px;
    background: rgba(24, 117, 240, 0);
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    font-size: 16px;
    font-family: 'Bold';
    font-weight: bold;
    color: #FFFFFF;
}

.guesContent{
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 23px;
    margin-bottom: 48px;

}
.guessBtn{
    width: 293px;
    padding: 0 63px;
    height: 56px;
    background: linear-gradient(90deg, #C93393 0%, #605FFA 66%, #605FFA 100%);
    border-radius: 4px;
    font-size: 20px;
    font-weight: normal;
    color: #FFFFFF; 
    line-height: 56px;
    cursor: pointer;
    text-align: center;
    font-family: "Roman";
}
.guessTitle{
    font-size: 74px;
    font-family: "Black";
    font-weight: 900;
    color: #FFFFFF;
    line-height: 101px;
    margin-top: 136px;
    width: 478px;
}
.guide{
    font-size: 44px;
    font-weight: 900;
    color: #FFFFFF;
    line-height: 60px;
    margin-bottom: 16px;
    margin-top: 56px;
    font-family: "Black";
}
.easy{
    font-size: 16px;
    font-weight: 500;
    color: #EBEBEB;
    line-height: 32px;
    font-family: 'Roman';
}
.guideBox{
    width: 366px;
    height: 289px;
    background: #FFFFFF;
    box-shadow: 0px 3px 3px 0px rgba(158, 158, 158, 0.5);
    border-radius: 28px;
    border: 1px solid #DCDCDC;
    padding: 0 30px 54px;
    text-align: center;
}
.orderNum{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin:0 auto;
    font-size: 58px;
    font-family: 'Bold';
    font-weight: bold;
    color: #FFFFFF;
    line-height: 65px;
    text-align: center;
    margin-top: -43px;
}
.deposit{
    font-size: 28px;
    font-weight: 600;
    color: #353535;
    line-height: 40px;
    margin-bottom: 38px;
    margin-top: 17px;
}
.txtLf{
    text-align: left;
    font-size: 16px;
}
.footerLogo{
    font-size: 24px;
    font-family: 'Bold';
    font-weight: bold;
    color: #FACC03;
    line-height: 29px;
    margin-left: 24px;
}
.interLink{
    font-size: 16px;
    font-family: 'Black';
    font-weight: 900;
    color: #FFFFFF;
    margin-bottom: 29px;
}
.link_txt:hover{
    color: #C83495;
}
.twitterDiv{
    margin-bottom:20px;
    overflow: hidden;
    cursor: pointer;
    background: url(~@/assets/home/Path.png) no-repeat;
    background-size: 17px 14px;
    background-position: 0;
}
.twitterDiv:hover{
    background: url(~@/assets/home/twitter.png) no-repeat;
    background-size: 17px 14px;
    background-position: 0;
    color: #C83495;
}
.discordDiv{
    margin-bottom:15px;
    overflow: hidden;
    cursor: pointer;
    background: url(~@/assets/home/icon.png) no-repeat;
    background-size: 17px 14px;
    background-position: 0;
}
.discordDiv:hover{
    background: url(~@/assets/home/discord.png) no-repeat;
    background-size: 17px 14px;
    background-position: 0;
    color: #C83495;
}
.hoverBlue:hover{
    color:#C83495;
    cursor: pointer;
}
.go_to{
    width: 24px;
    height: 14px;
    vertical-align: middle;
}

.s_icon1{
    width: 32px;
    height: 32px;
    margin-right: 20px;
    vertical-align: middle;
}
.p_c_w{
    font-size: 40px;
    font-weight: 900;
    color: #FFFFFF;
    line-height: 56px;
}
.p_c_w_c{
    width:60%;
    margin:0 auto;
    text-align: center;
}
.p_c_w_t{
    font-size: 16px;
    color: #EBEBEB;
    line-height: 32px;
}
.blue_bg{
    background: url(~@/assets/home/blue_bg.png) no-repeat;
    background-size: 100% 100%;
    background-position: 100% 100%;
}
.purple_bg{
    background: url(~@/assets/home/purple.png) no-repeat;
    background-size: 100% 100%;
    background-position: 100% 100%;
}
.lossless{
    width: 87%;
    margin: 0 auto;
    border: 2px solid rgba(255,255,255,0.1);
    border-radius: 32px;
    
    height: 614px;
    margin-top: 63px;
}
.loss_lf{
    width: 77%;
    margin-top: 130px;
    margin-left: 60px;
}
.loss_icon{
    width: 66px;
    margin-top: 116px;
}
.loss_title{
    font-size: 56px;
    font-weight: 900;
    color: #FFFFFF;
    line-height: 72px;
    margin-top: 24px;
    margin-bottom: 16px;
    font-family: "Black";
}
.loss_cont{
    font-size: 16px;
    color: #EBEBEB;
    line-height: 32px;
    font-family: "Roman";
}
.fair_icon{
    width: 66px;
    margin-top: 134px;
}
.mobile{
    width: 68%;
    margin-top: 135px;
    float: right;
}
.diver_lf{
    width: 73%;
    margin-top: 104px;
    margin-left: 60px;
}
.play_model{
    width: 87%;
    margin: 0 auto;
    border-radius: 32px;
    border: 2px solid rgba(255,255,255,0.1);
    padding: 69px 29px 31px;
    text-align: center;
    margin-top: 53px;
}
.play_img{
    width: 100px;
    height: 76px;
}
.p_title{
    font-size: 20px;
    font-weight: 900;
    color: #FFFFFF;
    line-height: 32px;
    margin: 10px 0 8px 0;
    font-family: "Black";
}
.p_cont{
    color: #CECCD6;
    font-size: 16px;
    line-height: 26px;
    width: 72%;
    margin:0 auto;
    font-family: "Roman";
}
.scroll_list{
    margin-top: 98px;
    margin-bottom: 40px;
}
.w_scroll_item{
    margin-top: 48px;
    margin-bottom: 111px;
}
.part_icon{
    width: 70%;
    cursor: pointer;
}
.partners_model{
    width: 87%;
    margin: 0px auto;
    display: flex;
    text-align: center;
    margin-top: 79px;
    margin-bottom: 154px;
}
.hoverBlue
{
    font-size: 14px;
    color: #FFFFFF;
    line-height: 19px;
}
.link_txt{
    margin-left:24px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 19px;
}
.p_img_1:hover{
    content: url(~@/assets/home/Primary_Logo_h.png);
}
.p_img_2:hover{
    content: url(~@/assets/home/AaveLogo_h.png);
}
.p_img_3:hover{
    content: url(~@/assets/home/ChainlinkLogo_h.png);
}
.p_img_4:hover{
    content: url(~@/assets/home/pinkSale_h.png);
}
.p_img_5:hover{
    content: url(~@/assets/home/dexview_h.png);
}



.my-list{
    margin-right: 108px;
    display: inline-block;
}
/* .my-uname{
    font-size: 20px;
    font-weight: 900;
    line-height: 63px;

} */
.pre_Box{
    overflow: hidden;
    color: #FFFFFF;
    height: 406px;
}
.user_Box{
    overflow: hidden;
    color: #FFFFFF;
}
.pre_Li{
    display: inline-block;
    margin-right: 16px;
    width: 368px;
    height: 406px;
    padding:24px;
    border: 1px solid #2C21AA;
    border-radius: 16px;
}


/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 21px;
  height: 40px;
  font-size: 25px;
  color: #fff;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
/* For demo */
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  display: inline-block;
  margin: auto;
  width: 100%;
  height: 25%;
}
.ant-carousel >>> .slick-thumb {
  bottom: -45px;
}
.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
.notice{
  display: inline-flex;
       align-items: center;
       padding: 0 20px;
       height: 50px;
       overflow: hidden;
       color: #fff;
       img{
           margin-right: 10px;
       }
       .tit{
           width: 50px;
       }
       .notice_con{
           width: 93%;
           padding-right: 20px;
           white-space: nowrap;
           overflow: hidden;
           color: #fff;
       }
   }
.messageBox {
  width: 100%;
  overflow: hidden;
    .div1 {
        width: 100%;
        height: 63px;
        line-height: 63px;
        background: linear-gradient(90deg, #FE890C 0%, #D549F5 100%);
        overflow: hidden;
        box-sizing: border-box;
    }
    .div1:hover .div2{
    -webkit-animation-play-state: paused; /*动画暂停播放*/
    }
    .div2 {
        white-space: nowrap;
        display: flex;
        color: #fff;
        font-size: 20px;
        font-family: "Black";
        animation: 10s anima linear infinite normal;
        cursor: pointer;
    }

}

</style>